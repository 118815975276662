<template>
    <div class="Box">
        <div class="leftBox">
            <textTitle :text="title"/>
            <div class="imgBox"  v-for="(item, index) in list" :key="index">
                    <div class="casesBox" @click="goparticulars(item)">
                         <div class="imgBox">
                            <img class="img" :src="item.mainPicRealPath" >
                            <h1>{{item.title}}</h1>
                            <span>培训时间：{{item.publishDate}}</span>
                        </div>
                    </div>
            </div>
        </div>
        <sidebar/>
    </div>
</template>
<script>
import textTitle from "@/components/headTail/textTitle";
import sidebar from "@/components/sidebar/sidebar";
/* import cases from "@/components/partner/case";
 */import { homeService } from "@/api"
import moment from "moment"
export default {
  components: {
    textTitle,
    sidebar,
   /*  cases, */
  },
  data() {
      return {
          list: [],
          title: this.$route.query.type == 3 ? '学员风采' : '企业培训案例'
      }
  },
  watch: {
    $route(){
      this.title = this.$route.query.type == 3 ? '学员风采' : '企业培训案例'
      this.getList()
    }
  },
 methods:{
     goparticulars(item){
        let detailId= item.id + ".html"
        this.$router.push({name: "particulars",params:{ id: detailId, ifjournalism: 1}})
     },
      /* 新闻列表 */
    getList() {
        let d = {
            type: this.$route.query.type,
            pageNum: 1,
            pageSize: 1000
        }
      homeService.getNewsList(d).then(res => {
        res.list.forEach(ele => {
            ele.publishDate = moment(ele.publishDate).format("YYYY-MM-DD")
        });
        this.list = res.list
      })
    }
 },
 created() {
     this.getList()
 }
};
</script>
<style lang="scss" scoped>
    .partner{
        float: left;
        width: 200px;
        height: 200px;
    }
    .casesBox{
        float: left;
        margin-left: 20px;
        margin-right: 20px;
        width: 360px;
    }
    .imgBox{
        margin: 10px 30px;
        .img{
            width: 300px;
            height: 200px;
            // background-color: sandybrown;
        }
        h1{
            text-align: center;
            font-size: 18px;
            margin: 10px 0;
        }
        span{
            display: block;
            text-align: center;
        }
    }
</style>